import React from 'react';

const AdviseToCoachingText = () => {
    return (
        <div className='about_h2'>
            <p>Professionnelle expérimentée de l’image, l’influence et  la réputation des organisations, des entreprises et de leurs dirigeants, j’apporte à mes clients un accompagnement sur-mesure dans la définition des messages clés, du discours adapté et des meilleures solutions pour convaincre leurs publics dans les moments décisifs.</p>
            <p>J’ai pendant 15 ans, construit mon expertise au sein de grandes agences conseil (Associée chez I&E/BCW -Burson Cohn & Wolfe- de 1991 à 2001 et Directrice du Planning Stratégique d’Euro RSCG Futur – HAVAS de 2001 à 2005) avant de créer Pôle R il y a 20 ans, une structure de conseil et de Coaching légère et agile qui place l’individu, sa singularité et ses objectifs au centre de l'accompagnement.</p>
            <p>C’est au contact des entreprises et de leurs dirigeants mais aussi du monde politique, que j’ai développé une approche particulière de l’accompagnement : le « Coaching décisif »</p>
            <ul>
                <li>Il s’intéresse d’abord à la singularité de celui qui va parler : votre personnalité est décisive dans le message que vous portez</li>
                <li>Il s’intéresse ensuite à l’enjeu de la prise de parole : comprendre l’effet recherché permet de travailler sur le fond, de structurer le propos et de choisir les mots efficaces au service du sens</li>
                <li>Il développe une approche empathique, attentive à la sensibilité du public qui va recevoir les messages autant qu’à celle de celui qui les émet</li>
                <li>Il apporte des méthodes éprouvées et durables pour maîtriser la forme du discours : gestion du stress, posture, voix, regard, révélation du charisme, force de conviction et maîtrise du temps</li>
            </ul>
        </div>
    );
};

export default AdviseToCoachingText;