import React from 'react';

const AdviseToCoaching = () => {
    return (
        <div className='advise_to_coaching_h3'>
            <h3 className='advise_to_coaching_h3_text'>Du conseil au coaching decisif</h3>
        </div>
    );
};

export default AdviseToCoaching;