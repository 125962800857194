import React from 'react';

const YouAre = () => {
    return (
        <div className='you_are'>
            <h3>Vous êtes...</h3>
        </div>
    );
};

export default YouAre;