
import React, { useState, useEffect } from 'react';
import '../grid/GridBackground.css';

const DecisiveCoachingIsText = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const texts = [
        'un appel d’offres, une compétition, un poste clé, …',
        'l’écoute de vos publics internes ou externes, collaborateurs, journalistes, jury, investisseurs…',
        'en confiance en vous, en plaisir et en efficacité dans vos prises de parole'
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 5000); // Change text every 5 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [texts.length]);

    return (
        <div className='coaching_is_h3'>
            {texts.map((text, index) => (
                <h3 key={index} className={index === currentIndex ? 'visible' : 'hidden'}>
                    {text}
                </h3>
            ))}
        </div>
    );
};

export default DecisiveCoachingIsText;