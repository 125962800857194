import React from 'react';

function Landmark({ name, year }) {
    return (
        <div className='landmark'>
            <div className='landmark_year'>({year})</div>
            <div className='landmark_name'>{name}</div>
        </div>
    );
}

export default Landmark;
