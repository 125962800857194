// import logo from './logo.svg';
import GridBackground from './components/grid/GridBackground';
// import Header from './components/Header';
// import Introduction from './components/Introduction';
// import Coaching from './components/Coaching';
// import Creed from './components/Creed';
// import Examples from './components/Examples';
// import Formats from './components/Formats';
// import References from './components/References';
// import Footer from './components/Footer';
import './App.css';
import JobList from './components/landmark/JobList';
import MainPhoto from './components/intro/MainPhoto';
import Introduction from './components/Introduction';
import AdviseToCoaching from './components/coaching/AdviseToCoaching';
import AdviseToCoachingText from './components/coaching/AdviseToCoachingText';
import DecisiveCoachingIs from './components/coaching/DecisiveCoachingIs';
import DecisiveCoachingIsText from './components/coaching/DecisiveCoachingIsText';
import DecisiveCoaching from './components/coaching/DecisiveCoaching';
import YouAre from './components/coaching/YouAre';

function App() {
  return (
    <GridBackground>
      <Introduction />
      {
        Array(90).fill().map((_, index) => (
          <div key={index} style={{ overflow: 'visible' }}></div>
        ))
      }
      <div className='landmark_h2'>
        <h2 className='landmark_h2_text'>Repères</h2>
      </div>
      <AdviseToCoaching />
      <AdviseToCoachingText />
      <DecisiveCoachingIs />
      <DecisiveCoachingIsText />
      <JobList />
      <YouAre></YouAre>
      <DecisiveCoaching></DecisiveCoaching>
      {/* <References></References> */}
    </GridBackground>
  );
};

export default App;
