import React from 'react';

const DecisiveCoachingIs = () => {
    return (
        <div className='coaching_is_h1'>
            <h1>Le « coaching décisif », c’est celui qui vous aide à gagner… </h1>
        </div>
    );
};

export default DecisiveCoachingIs;