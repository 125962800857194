import React from 'react';
import './GridBackground.css';

const GridBackground = ({ children }) => {
    return (
        <div className="grid-background">
            {children}
            {/* <div className='profile_img'>
                <img src="/assets/image_1.jpg" width="100%" alt="Profile" />
            </div> */}
        </div>
    );
};

export default GridBackground;