import React from 'react';

const DecisiveCoaching = () => {
    return (
        <div className='decisive_coaching_h3'>
            <ul>
                <li>dirigeants d’entreprises
                    <ul>
                        <li>de PME</li>
                        <li>de grands groupes</li>
                    </ul>
                </li>
                <li>d'organisations professionnelles</li>
                <li>hommes ou femmes politiques</li>
                <li>entrepreneurs</li>
                <li>candidats à des postes à hauts niveaux de responsabilité</li>
                <li>élus</li>
                <li>professionnels libéraux</li>
            </ul>
        </div>
    );
};

export default DecisiveCoaching;