import React from 'react';
import '../components/intro/Introduction.css';
import MainPhoto from './intro/MainPhoto';
import Name from './intro/Name';
import DecisiveCoachingIntro from './intro/DecisiveCoachingIntro';

const Introduction = () => {
    return (
        <>
            <Name />
            <MainPhoto />
            <DecisiveCoachingIntro />
        </>
    );
};

export default Introduction;