import React from 'react';

const MainPhoto = () => {
    return (
        <div className='profile_img'>
            <img src="/assets/image_1.jpg" width="100%" alt="Profile" />
        </div>
    );
};

export default MainPhoto;