import React from 'react';
import Landmark from './Landmark';
import '../grid/GridBackground.css';

const data = [
    {
        name: "Diplômée du Magistère du CELSA",
        year: "1991"
    },
    {
        name: "Formation « Process Com Coaching » Kahler Communication France",
        year: "2010"
    },
    {
        name: "Formation « Métier de Coach » IFG",
        year: "2017"
    },
    {
        name: "Fondatrice et Dirigeante de Pôle R (conseil & coaching) et PPEPP’s (Institut de Formation)",
        year: "2005-2024"
    },
    {
        name: "Directeur Conseil en charge de la Stratégie - Euro RSCG Futurs – HAVAS",
        year: "2001-2005"
    },
    {
        name: "Consultante, Directeur Conseil, Associée – IetE Consultants (BCW Burson Cohn &Wolfe)",
        year: "1991-2001"
    }
];

const JobList = () => {
    return (
        <>
            <div className="chronology_h2">
                {data.map((item, index) => (
                    <Landmark key={index} name={item.name} year={item.year} />
                ))}
            </div>
        </>
    );
};

export default JobList;