import React from 'react';

const Name = () => {
    return (
        <>
            <div className='name'>
                <h2 className='textName'>Christine Mariani</h2>
            </div>
        </>
    );
};

export default Name;