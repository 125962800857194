import React from 'react';

const DecisiveCoachingIntro = () => {
    return (
        <>
            <div className='decisive_coaching_intro_h1_p1'>
                <h1 className='decisive_coaching_intro_h1_p1_text'>Coac</h1>
            </div>
            <div className='decisive_coaching_intro_h1_p2'>
                <h1 className='decisive_coaching_intro_h1_p2_text'>hing décisif</h1>
            </div>
            <div className='for_executive_h1'>
                <h1 className='for_executive_text'>pour dirigeants</h1>
            </div>
            <div className='and_company_h1'>
                <h1 className='and_company_h1_text'>& entreprises</h1>
            </div>
        </>
    );
};

export default DecisiveCoachingIntro;